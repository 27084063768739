<template>
  <div class="product">
    <el-table :data="productData" height="500">
      <el-table-column label="序号">
        <template slot-scope="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="产品名称" prop="productName" />
      <el-table-column label="售后开始" prop="startDate" />
      <el-table-column label="售后结束" prop="endDate" />
    </el-table>
    <div class="product-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="pagination.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagination.pageSize"
        layout="total, prev, pager, next, sizes" :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
      productData: []
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pagination.currentPage = 1;
      this.pagination.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
    },
  }
}
</script>

<style lang="scss" scoped>
.product {
  padding: 30px;
  &-pagination {
    padding-top: 15px;
    text-align: right;
  }
}
</style>